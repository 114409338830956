import React, { PureComponent } from 'react';

import Archive16Icon from '@atlaskit/icon-file-type/glyph/archive/16';
import Archive24Icon from '@atlaskit/icon-file-type/glyph/archive/24';
import Audio16Icon from '@atlaskit/icon-file-type/glyph/audio/16';
import Audio24Icon from '@atlaskit/icon-file-type/glyph/audio/24';
import ExcelSpreadsheet16Icon from '@atlaskit/icon-file-type/glyph/excel-spreadsheet/16';
import ExcelSpreadsheet24Icon from '@atlaskit/icon-file-type/glyph/excel-spreadsheet/24';
import Generic16Icon from '@atlaskit/icon-file-type/glyph/generic/16';
import Generic24Icon from '@atlaskit/icon-file-type/glyph/generic/24';
import Gif16Icon from '@atlaskit/icon-file-type/glyph/gif/16';
import Gif24Icon from '@atlaskit/icon-file-type/glyph/gif/24';
import Image16Icon from '@atlaskit/icon-file-type/glyph/image/16';
import Image24Icon from '@atlaskit/icon-file-type/glyph/image/24';
import PdfDocument16Icon from '@atlaskit/icon-file-type/glyph/pdf-document/16';
import PdfDocument24Icon from '@atlaskit/icon-file-type/glyph/pdf-document/24';
import PowerpointPresentation16Icon from '@atlaskit/icon-file-type/glyph/powerpoint-presentation/16';
import PowerpointPresentation24Icon from '@atlaskit/icon-file-type/glyph/powerpoint-presentation/24';
import SourceCode16Icon from '@atlaskit/icon-file-type/glyph/source-code/16';
import SourceCode24Icon from '@atlaskit/icon-file-type/glyph/source-code/24';
import Video16Icon from '@atlaskit/icon-file-type/glyph/video/16';
import Video24Icon from '@atlaskit/icon-file-type/glyph/video/24';
import WordDocument16Icon from '@atlaskit/icon-file-type/glyph/word-document/16';
import WordDocument24Icon from '@atlaskit/icon-file-type/glyph/word-document/24';

export const sizes = ['small', 'medium'];

const AttachmentFileExtensionMatchers = [
	{
		regexp: /\.(pdf)$/i,
		icon: [PdfDocument16Icon, PdfDocument24Icon],
	},
	{
		regexp: /\.(gif)$/i,
		icon: [Gif16Icon, Gif24Icon],
	},
	{
		regexp: /\.(jpeg|jpg|png)$/i,
		icon: [Image16Icon, Image24Icon],
	},
	{
		regexp: /\.(xml|html|js|css|java|jar|war|ear)$/i,
		icon: [SourceCode16Icon, SourceCode24Icon],
	},
	{
		regexp: /\.(zip|tar|gz|bz2|rar)$/i,
		icon: [Archive16Icon, Archive24Icon],
	},
	{
		regexp: /\.(xlt|xls|xlsm|xlsx|xlst)$/i,
		icon: [ExcelSpreadsheet16Icon, ExcelSpreadsheet24Icon],
	},
	{
		regexp: /\.(pptx|ppsx|potx|pot|ppt|pptm)$/i,
		icon: [PowerpointPresentation16Icon, PowerpointPresentation24Icon],
	},
	{
		regexp: /\.(docx|dotx|doc|dot)$/i,
		icon: [WordDocument16Icon, WordDocument24Icon],
	},
	{
		regexp: /\.(wma|wmv|ram|mp3)$/i,
		icon: [Audio16Icon, Audio24Icon],
	},
	{
		regexp: /\.(mov|mpeg|mpg|mp4|avi)$/i,
		icon: [Video16Icon, Video24Icon],
	},
];

export const mediaTypes = {
	'application/pdf': [PdfDocument16Icon, PdfDocument24Icon],
	'image/gif': [Gif16Icon, Gif24Icon],
	'image/jpeg': [Image16Icon, Image24Icon],
	'image/png': [Image16Icon, Image24Icon],
	'text/xml': [SourceCode16Icon, SourceCode24Icon],
	'text/html': [SourceCode16Icon, SourceCode24Icon],
	'text/css': [SourceCode16Icon, SourceCode24Icon],
	'text/javascript': [SourceCode16Icon, SourceCode24Icon],
	'text/plain': [Generic16Icon, Generic24Icon], //NEED TO GIVE THIS AN ICON,
	'application/zip': [Archive16Icon, Archive24Icon],
	'application/x-zip': [Archive16Icon, Archive24Icon],
	'application/x-zip-compressed': [Archive16Icon, Archive24Icon],
	'application/java-archive': [Archive16Icon, Archive24Icon],
	'application/vnd.ms-excel': [ExcelSpreadsheet16Icon, ExcelSpreadsheet24Icon],
	'application/vnd.ms-powerpoint': [PowerpointPresentation16Icon, PowerpointPresentation24Icon],
	'application/msword': [WordDocument16Icon, WordDocument24Icon],
	'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [
		ExcelSpreadsheet16Icon,
		ExcelSpreadsheet24Icon,
	],
	'application/vnd.openxmlformats-officedocument.spreadsheetml.template': [
		ExcelSpreadsheet16Icon,
		ExcelSpreadsheet24Icon,
	],
	'application/vnd.openxmlformats-officedocument.presentationml.presentation': [
		PowerpointPresentation16Icon,
		PowerpointPresentation24Icon,
	],
	'application/vnd.openxmlformats-officedocument.presentationml.slideshow': [
		PowerpointPresentation16Icon,
		PowerpointPresentation24Icon,
	],
	'application/vnd.openxmlformats-officedocument.presentationml.template': [
		PowerpointPresentation16Icon,
		PowerpointPresentation24Icon,
	],
	'application/vnd.openxmlformats-officedocument.wordprocessingml.document': [
		WordDocument16Icon,
		WordDocument24Icon,
	],
	'application/vnd.openxmlformats-officedocument.wordprocessingml.template': [
		WordDocument16Icon,
		WordDocument24Icon,
	],
	'application/x-shockwave-flash': [Generic16Icon, Generic24Icon], //NEED TO GIVE THIS AN ICON
	'video/quicktime': [Video16Icon, Video24Icon],
	'audio/x-ms-wma': [Audio16Icon, Audio24Icon],
	'audio/x-ms-wmv': [Audio16Icon, Audio24Icon],
	'video/mpeg': [Video16Icon, Video24Icon],
	'audio/x-pn-realaudio': [Audio16Icon, Audio24Icon],
	'application/vnd.rn-realmedia': [Generic16Icon, Generic24Icon], //NEED TO GIVE THIS AN ICON
	'audio/mpeg': [Audio16Icon, Audio24Icon],
	'video/x-msvideo': [Video16Icon, Video24Icon],
	'video/mp4': [Video16Icon, Video24Icon],
	'audio/mp4': [Audio16Icon, Audio24Icon],
	'audio/mp3': [Audio16Icon, Audio24Icon],
	default: [Generic16Icon, Generic24Icon],
};

/**
 * Gets the media type of Content{graphql Content type} returned from graphql
 * @param extensions: graphql: Content.extensions
 * @returns {string | null}: If a media type exists return that type{string}, otherwise return null
 */
export const getMediaType = (extensions: Array<{ key: string; value: string }>) => {
	if (!extensions || !Array.isArray(extensions)) {
		return null;
	}
	const mediaType = extensions.find(({ key }) => key === 'mediaType');
	return mediaType ? mediaType.value : null;
};

export type AttachmentIconProps = {
	/** if mediaType is defined it's mapped to the proper icon  */
	mediaType?: string;
	/** if mediaType is undefined the extension of title is used to map to icon */
	title?: string;
	// Not every icon support higher size
	size?: 'small' | 'medium';
	label: string;
};

export class AttachmentIcon extends PureComponent<AttachmentIconProps> {
	getIconArray = () => {
		const { mediaType, title } = this.props;
		if (mediaType && mediaTypes[mediaType as keyof typeof mediaTypes]) {
			return mediaTypes[mediaType as keyof typeof mediaTypes];
		}
		if (title) {
			const extension = AttachmentFileExtensionMatchers.find((extensionMatcher) => {
				const matches = extensionMatcher.regexp.exec(title);
				return !!matches && matches.length > 0;
			});
			if (extension) {
				return extension.icon;
			}
		}
		return mediaTypes['default'];
	};

	render() {
		const { mediaType, label, size } = this.props;

		const iconArray = this.getIconArray();
		const sizeIndex = sizes.indexOf(size ?? 'medium') || 0;
		const icon = iconArray[sizeIndex];

		const testId = `attachment-icon-${mediaType?.replace('/', '-') ?? 'unknown'}`;

		return React.createElement(icon, {
			// @ts-expect-error FIXME: `size` is not an acceptable prop here
			size,
			label,
			testId,
		});
	}
}
